<div class="sidebar">
  @switch ((activeReport$ | async)?.report_type) {
    @case (reportTypes.F3) {
      <app-f3-menu></app-f3-menu>
    }
    @case (reportTypes.F3X) {
      <app-f3x-menu></app-f3x-menu>
    }
    @case (reportTypes.F99) {
      <app-f99-menu></app-f99-menu>
    }
    @case (reportTypes.F1M) {
      <app-f1m-menu></app-f1m-menu>
    }
    @case (reportTypes.F24) {
      <app-f24-menu></app-f24-menu>
    }
  }
</div>

import { SchATransaction, ScheduleATransactionTypes } from 'app/shared/models/scha-transaction.model';
import { SchBTransaction, ScheduleBTransactionTypes } from '../models/schb-transaction.model';
import { SchCTransaction, ScheduleCTransactionTypes } from '../models/schc-transaction.model';
import { SchC1Transaction } from '../models/schc1-transaction.model';
import { SchC2Transaction } from '../models/schc2-transaction.model';
import { SchDTransaction } from '../models/schd-transaction.model';
import { SchETransaction } from '../models/sche-transaction.model';
import { SchFTransaction, ScheduleFTransactionTypes } from '../models/schf-transaction.model';
import { ScheduleIds, ScheduleTransaction, TransactionTypes } from '../models/transaction.model';

// Schedule A /////////////////////////////////////////////////////
import { TransactionType } from '../models/transaction-type.model';
import { BUSINESS_LABOR_NON_CONTRIBUTION_ACCOUNT } from '../models/transaction-types/BUSINESS_LABOR_NON_CONTRIBUTION_ACCOUNT.model';
import { CONDUIT_EARMARK_RECEIPT } from '../models/transaction-types/CONDUIT_EARMARK_RECEIPT.model';
import { EARMARK_MEMO } from '../models/transaction-types/EARMARK_MEMO.model';
import { EARMARK_MEMO_CONVENTION_ACCOUNT } from '../models/transaction-types/EARMARK_MEMO_CONVENTION_ACCOUNT.model';
import { EARMARK_MEMO_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/EARMARK_MEMO_HEADQUARTERS_ACCOUNT.model';
import { EARMARK_MEMO_RECOUNT_ACCOUNT } from '../models/transaction-types/EARMARK_MEMO_RECOUNT_ACCOUNT.model';
import { EARMARK_RECEIPT } from '../models/transaction-types/EARMARK_RECEIPT.model';
import { EARMARK_RECEIPT_CONVENTION_ACCOUNT } from '../models/transaction-types/EARMARK_RECEIPT_CONVENTION_ACCOUNT.model';
import { EARMARK_RECEIPT_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/EARMARK_RECEIPT_HEADQUARTERS_ACCOUNT.model';
import { EARMARK_RECEIPT_RECOUNT_ACCOUNT } from '../models/transaction-types/EARMARK_RECEIPT_RECOUNT_ACCOUNT.model';
import { INDIVIDUAL_JF_TRANSFER_MEMO } from '../models/transaction-types/INDIVIDUAL_JF_TRANSFER_MEMO.model';
import { INDIVIDUAL_NATIONAL_PARTY_CONVENTION_ACCOUNT } from '../models/transaction-types/INDIVIDUAL_NATIONAL_PARTY_CONVENTION_ACCOUNT.model';
import { INDIVIDUAL_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO } from '../models/transaction-types/INDIVIDUAL_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO.model';
import { INDIVIDUAL_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/INDIVIDUAL_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT.model';
import { INDIVIDUAL_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO } from '../models/transaction-types/INDIVIDUAL_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO.model';
import { INDIVIDUAL_NATIONAL_PARTY_RECOUNT_ACCOUNT } from '../models/transaction-types/INDIVIDUAL_NATIONAL_PARTY_RECOUNT_ACCOUNT.model';
import { INDIVIDUAL_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO } from '../models/transaction-types/INDIVIDUAL_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO.model';
import { INDIVIDUAL_RECEIPT } from '../models/transaction-types/INDIVIDUAL_RECEIPT.model';
import { INDIVIDUAL_RECEIPT_NON_CONTRIBUTION_ACCOUNT } from '../models/transaction-types/INDIVIDUAL_RECEIPT_NON_CONTRIBUTION_ACCOUNT.model';
import { INDIVIDUAL_RECOUNT_RECEIPT } from '../models/transaction-types/INDIVIDUAL_RECOUNT_RECEIPT.model';
import { IN_KIND_RECEIPT } from '../models/transaction-types/IN_KIND_RECEIPT.model';
import { IN_KIND_TRANSFER } from '../models/transaction-types/IN_KIND_TRANSFER.model';
import { IN_KIND_TRANSFER_FEDERAL_ELECTION_ACTIVITY } from '../models/transaction-types/IN_KIND_TRANSFER_FEDERAL_ELECTION_ACTIVITY.model';
import { JF_TRANSFER_NATIONAL_PARTY_CONVENTION_ACCOUNT } from '../models/transaction-types/JF_TRANSFER_NATIONAL_PARTY_CONVENTION_ACCOUNT.model';
import { JF_TRANSFER_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/JF_TRANSFER_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT.model';
import { JF_TRANSFER_NATIONAL_PARTY_RECOUNT_ACCOUNT } from '../models/transaction-types/JF_TRANSFER_NATIONAL_PARTY_RECOUNT_ACCOUNT.model';
import { JOINT_FUNDRAISING_TRANSFER } from '../models/transaction-types/JOINT_FUNDRAISING_TRANSFER.model';
import { LOAN_RECEIVED_FROM_BANK_RECEIPT } from '../models/transaction-types/LOAN_RECEIVED_FROM_BANK_RECEIPT.model';
import { LOAN_RECEIVED_FROM_INDIVIDUAL_RECEIPT } from '../models/transaction-types/LOAN_RECEIVED_FROM_INDIVIDUAL_RECEIPT.model';
import { LOAN_REPAYMENT_RECEIVED } from '../models/transaction-types/LOAN_REPAYMENT_RECEIVED.model';
import { OFFSET_TO_OPERATING_EXPENDITURES } from '../models/transaction-types/OFFSET_TO_OPERATING_EXPENDITURES.model';
import { OTHER_COMMITTEE_NON_CONTRIBUTION_ACCOUNT } from '../models/transaction-types/OTHER_COMMITTEE_NON_CONTRIBUTION_ACCOUNT.model';
import { OTHER_RECEIPT } from '../models/transaction-types/OTHER_RECEIPT.model';
import { PAC_CONDUIT_EARMARK } from '../models/transaction-types/PAC_CONDUIT_EARMARK.model';
import { PAC_EARMARK_MEMO } from '../models/transaction-types/PAC_EARMARK_MEMO.model';
import { PAC_EARMARK_RECEIPT } from '../models/transaction-types/PAC_EARMARK_RECEIPT.model';
import { PAC_IN_KIND_RECEIPT } from '../models/transaction-types/PAC_IN_KIND_RECEIPT.model';
import { PAC_JF_TRANSFER_MEMO } from '../models/transaction-types/PAC_JF_TRANSFER_MEMO.model';
import { PAC_NATIONAL_PARTY_CONVENTION_ACCOUNT } from '../models/transaction-types/PAC_NATIONAL_PARTY_CONVENTION_ACCOUNT.model';
import { PAC_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO } from '../models/transaction-types/PAC_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO.model';
import { PAC_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/PAC_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT.model';
import { PAC_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO } from '../models/transaction-types/PAC_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO.model';
import { PAC_NATIONAL_PARTY_RECOUNT_ACCOUNT } from '../models/transaction-types/PAC_NATIONAL_PARTY_RECOUNT_ACCOUNT.model';
import { PAC_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO } from '../models/transaction-types/PAC_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO.model';
import { PAC_RECEIPT } from '../models/transaction-types/PAC_RECEIPT.model';
import { PAC_RECOUNT_RECEIPT } from '../models/transaction-types/PAC_RECOUNT_RECEIPT.model';
import { PAC_RETURN } from '../models/transaction-types/PAC_RETURN.model';
import { PARTNERSHIP_ATTRIBUTION } from '../models/transaction-types/PARTNERSHIP_ATTRIBUTION.model';
import { PARTNERSHIP_ATTRIBUTION_JF_TRANSFER_MEMO } from '../models/transaction-types/PARTNERSHIP_ATTRIBUTION_JF_TRANSFER_MEMO.model';
import { PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_CONVENTION_ACCOUNT_MEMO } from '../models/transaction-types/PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_CONVENTION_ACCOUNT_MEMO.model';
import { PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT_MEMO } from '../models/transaction-types/PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT_MEMO.model';
import { PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO } from '../models/transaction-types/PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO.model';
import { PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_RECOUNT_ACCOUNT_MEMO } from '../models/transaction-types/PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_RECOUNT_ACCOUNT_MEMO.model';
import { PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO } from '../models/transaction-types/PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO.model';
import { PARTNERSHIP_ATTRIBUTION_RECOUNT_ACCOUNT_RECEIPT_MEMO } from '../models/transaction-types/PARTNERSHIP_ATTRIBUTION_RECOUNT_ACCOUNT_RECEIPT_MEMO.model';
import { PARTNERSHIP_JF_TRANSFER_MEMO } from '../models/transaction-types/PARTNERSHIP_JF_TRANSFER_MEMO.model';
import { PARTNERSHIP_NATIONAL_PARTY_CONVENTION_ACCOUNT } from '../models/transaction-types/PARTNERSHIP_NATIONAL_PARTY_CONVENTION_ACCOUNT.model';
import { PARTNERSHIP_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/PARTNERSHIP_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT.model';
import { PARTNERSHIP_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO } from '../models/transaction-types/PARTNERSHIP_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO.model';
import { PARTNERSHIP_NATIONAL_PARTY_RECOUNT_ACCOUNT } from '../models/transaction-types/PARTNERSHIP_NATIONAL_PARTY_RECOUNT_ACCOUNT.model';
import { PARTNERSHIP_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO } from '../models/transaction-types/PARTNERSHIP_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO.model';
import { PARTNERSHIP_RECEIPT } from '../models/transaction-types/PARTNERSHIP_RECEIPT.model';
import { PARTNERSHIP_RECOUNT_ACCOUNT_RECEIPT } from '../models/transaction-types/PARTNERSHIP_RECOUNT_ACCOUNT_RECEIPT.model';
import { PARTY_IN_KIND_RECEIPT } from '../models/transaction-types/PARTY_IN_KIND_RECEIPT.model';
import { PARTY_JF_TRANSFER_MEMO } from '../models/transaction-types/PARTY_JF_TRANSFER_MEMO.model';
import { PARTY_NATIONAL_PARTY_CONVENTION_ACCOUNT } from '../models/transaction-types/PARTY_NATIONAL_PARTY_CONVENTION_ACCOUNT.model';
import { PARTY_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/PARTY_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT.model';
import { PARTY_NATIONAL_PARTY_RECOUNT_ACCOUNT } from '../models/transaction-types/PARTY_NATIONAL_PARTY_RECOUNT_ACCOUNT.model';
import { PARTY_RECEIPT } from '../models/transaction-types/PARTY_RECEIPT.model';
import { PARTY_RECOUNT_RECEIPT } from '../models/transaction-types/PARTY_RECOUNT_RECEIPT.model';
import { PARTY_RETURN } from '../models/transaction-types/PARTY_RETURN.model';
import { RECEIPT_FROM_UNREGISTERED_ENTITY } from '../models/transaction-types/RECEIPT_FROM_UNREGISTERED_ENTITY.model';
import { RECEIPT_FROM_UNREGISTERED_ENTITY_RETURN } from '../models/transaction-types/RECEIPT_FROM_UNREGISTERED_ENTITY_RETURN.model';
import { REFUND_TO_OTHER_POLITICAL_COMMITTEE } from '../models/transaction-types/REFUND_TO_OTHER_POLITICAL_COMMITTEE.model';
import { RETURN_RECEIPT } from '../models/transaction-types/RETURN_RECEIPT.model';
import { TRANSFER } from '../models/transaction-types/TRANSFER.model';
import { TRIBAL_JF_TRANSFER_MEMO } from '../models/transaction-types/TRIBAL_JF_TRANSFER_MEMO.model';
import { TRIBAL_NATIONAL_PARTY_CONVENTION_ACCOUNT } from '../models/transaction-types/TRIBAL_NATIONAL_PARTY_CONVENTION_ACCOUNT.model';
import { TRIBAL_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO } from '../models/transaction-types/TRIBAL_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO.model';
import { TRIBAL_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/TRIBAL_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT.model';
import { TRIBAL_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO } from '../models/transaction-types/TRIBAL_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO.model';
import { TRIBAL_NATIONAL_PARTY_RECOUNT_ACCOUNT } from '../models/transaction-types/TRIBAL_NATIONAL_PARTY_RECOUNT_ACCOUNT.model';
import { TRIBAL_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO } from '../models/transaction-types/TRIBAL_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO.model';
import { TRIBAL_RECEIPT } from '../models/transaction-types/TRIBAL_RECEIPT.model';
import { TRIBAL_RECOUNT_RECEIPT } from '../models/transaction-types/TRIBAL_RECOUNT_RECEIPT.model';

// Schedule B /////////////////////////////////////////////////////
import { BUSINESS_LABOR_REFUND_NON_CONTRIBUTION_ACCOUNT } from '../models/transaction-types/BUSINESS_LABOR_REFUND_NON_CONTRIBUTION_ACCOUNT.model';
import { CONDUIT_EARMARK_OUT } from '../models/transaction-types/CONDUIT_EARMARK_OUT.model';
import { CONTRIBUTION_TO_CANDIDATE } from '../models/transaction-types/CONTRIBUTION_TO_CANDIDATE.model';
import { CONTRIBUTION_TO_CANDIDATE_VOID } from '../models/transaction-types/CONTRIBUTION_TO_CANDIDATE_VOID.model';
import { CONTRIBUTION_TO_OTHER_COMMITTEE } from '../models/transaction-types/CONTRIBUTION_TO_OTHER_COMMITTEE.model';
import { CONTRIBUTION_TO_OTHER_COMMITTEE_VOID } from '../models/transaction-types/CONTRIBUTION_TO_OTHER_COMMITTEE_VOID.model';
import { FEDERAL_ELECTION_ACTIVITY_100PCT_PAYMENT } from '../models/transaction-types/FEDERAL_ELECTION_ACTIVITY_100PCT_PAYMENT.model';
import { FEDERAL_ELECTION_ACTIVITY_CREDIT_CARD_PAYMENT } from '../models/transaction-types/FEDERAL_ELECTION_ACTIVITY_CREDIT_CARD_PAYMENT.model';
import { FEDERAL_ELECTION_ACTIVITY_CREDIT_CARD_PAYMENT_MEMO } from '../models/transaction-types/FEDERAL_ELECTION_ACTIVITY_CREDIT_CARD_PAYMENT_MEMO.model';
import { FEDERAL_ELECTION_ACTIVITY_PAYMENT_TO_PAYROLL } from '../models/transaction-types/FEDERAL_ELECTION_ACTIVITY_PAYMENT_TO_PAYROLL.model';
import { FEDERAL_ELECTION_ACTIVITY_PAYMENT_TO_PAYROLL_MEMO } from '../models/transaction-types/FEDERAL_ELECTION_ACTIVITY_PAYMENT_TO_PAYROLL_MEMO.model';
import { FEDERAL_ELECTION_ACTIVITY_STAFF_REIMBURSEMENT } from '../models/transaction-types/FEDERAL_ELECTION_ACTIVITY_STAFF_REIMBURSEMENT.model';
import { FEDERAL_ELECTION_ACTIVITY_STAFF_REIMBURSEMENT_MEMO } from '../models/transaction-types/FEDERAL_ELECTION_ACTIVITY_STAFF_REIMBURSEMENT_MEMO.model';
import { FEDERAL_ELECTION_ACTIVITY_VOID } from '../models/transaction-types/FEDERAL_ELECTION_ACTIVITY_VOID.model';
import { INDIVIDUAL_REFUND_NON_CONTRIBUTION_ACCOUNT } from '../models/transaction-types/INDIVIDUAL_REFUND_NON_CONTRIBUTION_ACCOUNT.model';
import { INDIVIDUAL_REFUND_NP_CONVENTION_ACCOUNT } from '../models/transaction-types/INDIVIDUAL_REFUND_NP_CONVENTION_ACCOUNT.model';
import { INDIVIDUAL_REFUND_NP_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/INDIVIDUAL_REFUND_NP_HEADQUARTERS_ACCOUNT.model';
import { INDIVIDUAL_REFUND_NP_RECOUNT_ACCOUNT } from '../models/transaction-types/INDIVIDUAL_REFUND_NP_RECOUNT_ACCOUNT.model';
import { IN_KIND_CONTRIBUTION_TO_CANDIDATE } from '../models/transaction-types/IN_KIND_CONTRIBUTION_TO_CANDIDATE.model';
import { IN_KIND_CONTRIBUTION_TO_OTHER_COMMITTEE } from '../models/transaction-types/IN_KIND_CONTRIBUTION_TO_OTHER_COMMITTEE.model';
import { IN_KIND_OUT } from '../models/transaction-types/IN_KIND_OUT.model';
import { IN_KIND_TRANSFER_FEA_OUT } from '../models/transaction-types/IN_KIND_TRANSFER_FEA_OUT.model';
import { IN_KIND_TRANSFER_OUT } from '../models/transaction-types/IN_KIND_TRANSFER_OUT.model';
import { LOAN_MADE } from '../models/transaction-types/LOAN_MADE.model';
import { LOAN_REPAYMENT_MADE } from '../models/transaction-types/LOAN_REPAYMENT_MADE.model';
import { NATIONAL_PARTY_CONVENTION_ACCOUNT_DISBURSEMENT } from '../models/transaction-types/NATIONAL_PARTY_CONVENTION_ACCOUNT_DISBURSEMENT.model';
import { NATIONAL_PARTY_HEADQUARTERS_ACCOUNT_DISBURSEMENT } from '../models/transaction-types/NATIONAL_PARTY_HEADQUARTERS_ACCOUNT_DISBURSEMENT.model';
import { NATIONAL_PARTY_RECOUNT_ACCOUNT_DISBURSEMENT } from '../models/transaction-types/NATIONAL_PARTY_RECOUNT_ACCOUNT_DISBURSEMENT.model';
import { NON_CONTRIBUTION_ACCOUNT_CREDIT_CARD_PAYMENT } from '../models/transaction-types/NON_CONTRIBUTION_ACCOUNT_CREDIT_CARD_PAYMENT.model';
import { NON_CONTRIBUTION_ACCOUNT_CREDIT_CARD_PAYMENT_MEMO } from '../models/transaction-types/NON_CONTRIBUTION_ACCOUNT_CREDIT_CARD_PAYMENT_MEMO.model';
import { NON_CONTRIBUTION_ACCOUNT_DISBURSEMENT } from '../models/transaction-types/NON_CONTRIBUTION_ACCOUNT_DISBURSEMENT.model';
import { NON_CONTRIBUTION_ACCOUNT_PAYMENT_TO_PAYROLL } from '../models/transaction-types/NON_CONTRIBUTION_ACCOUNT_PAYMENT_TO_PAYROLL.model';
import { NON_CONTRIBUTION_ACCOUNT_PAYMENT_TO_PAYROLL_MEMO } from '../models/transaction-types/NON_CONTRIBUTION_ACCOUNT_PAYMENT_TO_PAYROLL_MEMO.model';
import { NON_CONTRIBUTION_ACCOUNT_STAFF_REIMBURSEMENT } from '../models/transaction-types/NON_CONTRIBUTION_ACCOUNT_STAFF_REIMBURSEMENT.model';
import { NON_CONTRIBUTION_ACCOUNT_STAFF_REIMBURSEMENT_MEMO } from '../models/transaction-types/NON_CONTRIBUTION_ACCOUNT_STAFF_REIMBURSEMENT_MEMO.model';
import { OPERATING_EXPENDITURE } from '../models/transaction-types/OPERATING_EXPENDITURE.model';
import { OPERATING_EXPENDITURE_CREDIT_CARD_PAYMENT } from '../models/transaction-types/OPERATING_EXPENDITURE_CREDIT_CARD_PAYMENT.model';
import { OPERATING_EXPENDITURE_CREDIT_CARD_PAYMENT_MEMO } from '../models/transaction-types/OPERATING_EXPENDITURE_CREDIT_CARD_PAYMENT_MEMO.model';
import { OPERATING_EXPENDITURE_PAYMENT_TO_PAYROLL } from '../models/transaction-types/OPERATING_EXPENDITURE_PAYMENT_TO_PAYROLL.model';
import { OPERATING_EXPENDITURE_PAYMENT_TO_PAYROLL_MEMO } from '../models/transaction-types/OPERATING_EXPENDITURE_PAYMENT_TO_PAYROLL_MEMO.model';
import { OPERATING_EXPENDITURE_STAFF_REIMBURSEMENT } from '../models/transaction-types/OPERATING_EXPENDITURE_STAFF_REIMBURSEMENT.model';
import { OPERATING_EXPENDITURE_STAFF_REIMBURSEMENT_MEMO } from '../models/transaction-types/OPERATING_EXPENDITURE_STAFF_REIMBURSEMENT_MEMO.model';
import { OPERATING_EXPENDITURE_VOID } from '../models/transaction-types/OPERATING_EXPENDITURE_VOID.model';
import { OTHER_COMMITTEE_REFUND_NON_CONTRIBUTION_ACCOUNT } from '../models/transaction-types/OTHER_COMMITTEE_REFUND_NON_CONTRIBUTION_ACCOUNT.model';
import { OTHER_COMMITTEE_REFUND_REFUND_NP_CONVENTION_ACCOUNT } from '../models/transaction-types/OTHER_COMMITTEE_REFUND_REFUND_NP_CONVENTION_ACCOUNT.model';
import { OTHER_COMMITTEE_REFUND_REFUND_NP_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/OTHER_COMMITTEE_REFUND_REFUND_NP_HEADQUARTERS_ACCOUNT.model';
import { OTHER_COMMITTEE_REFUND_REFUND_NP_RECOUNT_ACCOUNT } from '../models/transaction-types/OTHER_COMMITTEE_REFUND_REFUND_NP_RECOUNT_ACCOUNT.model';
import { OTHER_DISBURSEMENT } from '../models/transaction-types/OTHER_DISBURSEMENT.model';
import { OTHER_DISBURSEMENT_CREDIT_CARD_PAYMENT } from '../models/transaction-types/OTHER_DISBURSEMENT_CREDIT_CARD_PAYMENT.model';
import { OTHER_DISBURSEMENT_CREDIT_CARD_PAYMENT_MEMO } from '../models/transaction-types/OTHER_DISBURSEMENT_CREDIT_CARD_PAYMENT_MEMO.model';
import { OTHER_DISBURSEMENT_PAYMENT_TO_PAYROLL } from '../models/transaction-types/OTHER_DISBURSEMENT_PAYMENT_TO_PAYROLL.model';
import { OTHER_DISBURSEMENT_PAYMENT_TO_PAYROLL_MEMO } from '../models/transaction-types/OTHER_DISBURSEMENT_PAYMENT_TO_PAYROLL_MEMO.model';
import { OTHER_DISBURSEMENT_STAFF_REIMBURSEMENT } from '../models/transaction-types/OTHER_DISBURSEMENT_STAFF_REIMBURSEMENT.model';
import { OTHER_DISBURSEMENT_STAFF_REIMBURSEMENT_MEMO } from '../models/transaction-types/OTHER_DISBURSEMENT_STAFF_REIMBURSEMENT_MEMO.model';
import { OTHER_DISBURSEMENT_VOID } from '../models/transaction-types/OTHER_DISBURSEMENT_VOID.model';
import { PAC_CONDUIT_EARMARK_OUT } from '../models/transaction-types/PAC_CONDUIT_EARMARK_OUT.model';
import { PAC_IN_KIND_OUT } from '../models/transaction-types/PAC_IN_KIND_OUT.model';
import { PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO } from '../models/transaction-types/PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO.model';
import { PARTNERSHIP_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO } from '../models/transaction-types/PARTNERSHIP_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO.model';
import { PARTY_IN_KIND_OUT } from '../models/transaction-types/PARTY_IN_KIND_OUT.model';
import { RECOUNT_ACCOUNT_DISBURSEMENT } from '../models/transaction-types/RECOUNT_ACCOUNT_DISBURSEMENT.model';
import { REFUND_INDIVIDUAL_CONTRIBUTION } from '../models/transaction-types/REFUND_INDIVIDUAL_CONTRIBUTION.model';
import { REFUND_INDIVIDUAL_CONTRIBUTION_VOID } from '../models/transaction-types/REFUND_INDIVIDUAL_CONTRIBUTION_VOID.model';
import { REFUND_PAC_CONTRIBUTION } from '../models/transaction-types/REFUND_PAC_CONTRIBUTION.model';
import { REFUND_PAC_CONTRIBUTION_VOID } from '../models/transaction-types/REFUND_PAC_CONTRIBUTION_VOID.model';
import { REFUND_PARTY_CONTRIBUTION } from '../models/transaction-types/REFUND_PARTY_CONTRIBUTION.model';
import { REFUND_PARTY_CONTRIBUTION_VOID } from '../models/transaction-types/REFUND_PARTY_CONTRIBUTION_VOID.model';
import { REFUND_TO_FEDERAL_CANDIDATE } from '../models/transaction-types/REFUND_TO_FEDERAL_CANDIDATE.model';
import { REFUND_TO_UNREGISTERED_COMMITTEE } from '../models/transaction-types/REFUND_TO_UNREGISTERED_COMMITTEE.model';
import { REFUND_UNREGISTERED_CONTRIBUTION } from '../models/transaction-types/REFUND_UNREGISTERED_CONTRIBUTION.model';
import { REFUND_UNREGISTERED_CONTRIBUTION_VOID } from '../models/transaction-types/REFUND_UNREGISTERED_CONTRIBUTION_VOID.model';
import { TRANSFER_TO_AFFILIATES } from '../models/transaction-types/TRANSFER_TO_AFFILIATES.model';
import { TRIBAL_REFUND_NP_CONVENTION_ACCOUNT } from '../models/transaction-types/TRIBAL_REFUND_NP_CONVENTION_ACCOUNT.model';
import { TRIBAL_REFUND_NP_HEADQUARTERS_ACCOUNT } from '../models/transaction-types/TRIBAL_REFUND_NP_HEADQUARTERS_ACCOUNT.model';
import { TRIBAL_REFUND_NP_RECOUNT_ACCOUNT } from '../models/transaction-types/TRIBAL_REFUND_NP_RECOUNT_ACCOUNT.model';

// Schedule C /////////////////////////////////////////////////////
import { LOAN_BY_COMMITTEE } from '../models/transaction-types/LOAN_BY_COMMITTEE.model';
import { LOAN_RECEIVED_FROM_BANK } from '../models/transaction-types/LOAN_RECEIVED_FROM_BANK.model';
import { LOAN_RECEIVED_FROM_INDIVIDUAL } from '../models/transaction-types/LOAN_RECEIVED_FROM_INDIVIDUAL.model';

// Schedule C1 ////////////////////////////////////////////////////
import { C1_LOAN_AGREEMENT } from '../models/transaction-types/C1_LOAN_AGREEMENT.model';

// Schedule C2 ////////////////////////////////////////////////////
import { C2_LOAN_GUARANTOR } from '../models/transaction-types/C2_LOAN_GUARANTOR.model';

// Schedule D ////////////////////////////////////////////////////
import { DEBT_OWED_BY_COMMITTEE } from '../models/transaction-types/DEBT_OWED_BY_COMMITTEE.model';
import { DEBT_OWED_TO_COMMITTEE } from '../models/transaction-types/DEBT_OWED_TO_COMMITTEE.model';

// Schedule E ////////////////////////////////////////////////////
import { INDEPENDENT_EXPENDITURE } from '../models/transaction-types/INDEPENDENT_EXPENDITURE.model';
import { INDEPENDENT_EXPENDITURE_CREDIT_CARD_PAYMENT } from '../models/transaction-types/INDEPENDENT_EXPENDITURE_CREDIT_CARD_PAYMENT.model';
import { INDEPENDENT_EXPENDITURE_CREDIT_CARD_PAYMENT_MEMO } from '../models/transaction-types/INDEPENDENT_EXPENDITURE_CREDIT_CARD_PAYMENT_MEMO.model';
import { INDEPENDENT_EXPENDITURE_PAYMENT_TO_PAYROLL } from '../models/transaction-types/INDEPENDENT_EXPENDITURE_PAYMENT_TO_PAYROLL.model';
import { INDEPENDENT_EXPENDITURE_PAYMENT_TO_PAYROLL_MEMO } from '../models/transaction-types/INDEPENDENT_EXPENDITURE_PAYMENT_TO_PAYROLL_MEMO.model';
import { INDEPENDENT_EXPENDITURE_STAFF_REIMBURSEMENT } from '../models/transaction-types/INDEPENDENT_EXPENDITURE_STAFF_REIMBURSEMENT.model';
import { INDEPENDENT_EXPENDITURE_STAFF_REIMBURSEMENT_MEMO } from '../models/transaction-types/INDEPENDENT_EXPENDITURE_STAFF_REIMBURSEMENT_MEMO.model';
import { INDEPENDENT_EXPENDITURE_VOID } from '../models/transaction-types/INDEPENDENT_EXPENDITURE_VOID.model';
import { MULTISTATE_INDEPENDENT_EXPENDITURE } from '../models/transaction-types/MULTISTATE_INDEPENDENT_EXPENDITURE.model';

// Schedule F ////////////////////////////////////////////////////
import { COORDINATED_PARTY_EXPENDITURE } from '../models/transaction-types/COORDINATED_PARTY_EXPENDITURE.model';
import { COORDINATED_PARTY_EXPENDITURE_VOID } from '../models/transaction-types/COORDINATED_PARTY_EXPENDITURE_VOID.model';

// prettier-ignore
const transactionTypeClasses: any = { // eslint-disable-line @typescript-eslint/no-explicit-any
  // Schedule A /////////////////////////////////////////////////////
  EARMARK_RECEIPT,
  EARMARK_MEMO,
  IN_KIND_RECEIPT,
  IN_KIND_OUT,
  INDIVIDUAL_JF_TRANSFER_MEMO,
  INDIVIDUAL_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO,
  INDIVIDUAL_RECEIPT,
  INDIVIDUAL_RECOUNT_RECEIPT,
  JF_TRANSFER_NATIONAL_PARTY_CONVENTION_ACCOUNT,
  JF_TRANSFER_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
  JOINT_FUNDRAISING_TRANSFER,
  OFFSET_TO_OPERATING_EXPENDITURES,
  OTHER_COMMITTEE_NON_CONTRIBUTION_ACCOUNT,
  OTHER_RECEIPT,
  PAC_CONDUIT_EARMARK,
  PAC_CONDUIT_EARMARK_RECEIPT_DEPOSITED: PAC_CONDUIT_EARMARK,
  PAC_CONDUIT_EARMARK_RECEIPT_UNDEPOSITED: PAC_CONDUIT_EARMARK,
  PAC_JF_TRANSFER_MEMO,
  PAC_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO,
  PAC_NATIONAL_PARTY_RECOUNT_ACCOUNT,
  PAC_EARMARK_RECEIPT,
  PAC_EARMARK_MEMO,
  PAC_RECEIPT,
  PAC_RECOUNT_RECEIPT,
  PARTNERSHIP_ATTRIBUTION,
  PARTNERSHIP_RECEIPT,
  PARTNERSHIP_JF_TRANSFER_MEMO,
  PARTNERSHIP_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO,
  PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO,
  PARTNERSHIP_ATTRIBUTION_JF_TRANSFER_MEMO,
  PARTY_JF_TRANSFER_MEMO,
  PARTY_RECEIPT,
  PARTY_RECOUNT_RECEIPT,
  REFUND_TO_FEDERAL_CANDIDATE,
  REFUND_TO_UNREGISTERED_COMMITTEE,
  RETURN_RECEIPT,
  TRANSFER,
  TRIBAL_JF_TRANSFER_MEMO,
  TRIBAL_NATIONAL_PARTY_CONVENTION_JF_TRANSFER_MEMO,
  TRIBAL_RECEIPT,
  TRIBAL_RECOUNT_RECEIPT,
  BUSINESS_LABOR_NON_CONTRIBUTION_ACCOUNT,
  JF_TRANSFER_NATIONAL_PARTY_RECOUNT_ACCOUNT,
  PAC_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO,
  TRIBAL_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO,
  INDIVIDUAL_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO,
  INDIVIDUAL_RECEIPT_NON_CONTRIBUTION_ACCOUNT,
  INDIVIDUAL_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
  PARTY_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
  PAC_NATIONAL_PARTY_CONVENTION_ACCOUNT,
  PAC_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
  TRIBAL_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
  TRIBAL_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO,
  TRIBAL_NATIONAL_PARTY_CONVENTION_ACCOUNT,
  INDIVIDUAL_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO,
  PAC_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO,
  PARTNERSHIP_NATIONAL_PARTY_RECOUNT_ACCOUNT,
  PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_RECOUNT_ACCOUNT_MEMO,
  PARTNERSHIP_NATIONAL_PARTY_CONVENTION_ACCOUNT,
  PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_CONVENTION_ACCOUNT_MEMO,
  PARTNERSHIP_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
  PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT_MEMO,
  PARTY_NATIONAL_PARTY_RECOUNT_ACCOUNT,
  INDIVIDUAL_NATIONAL_PARTY_RECOUNT_ACCOUNT,
  INDIVIDUAL_NATIONAL_PARTY_CONVENTION_ACCOUNT,
  PARTY_NATIONAL_PARTY_CONVENTION_ACCOUNT,
  TRIBAL_NATIONAL_PARTY_RECOUNT_ACCOUNT,
  RECEIPT_FROM_UNREGISTERED_ENTITY,
  RECEIPT_FROM_UNREGISTERED_ENTITY_RETURN,
  PAC_RETURN,
  PARTY_RETURN,
  EARMARK_RECEIPT_HEADQUARTERS_ACCOUNT,
  EARMARK_RECEIPT_RECOUNT_ACCOUNT,
  EARMARK_RECEIPT_CONVENTION_ACCOUNT,
  EARMARK_MEMO_HEADQUARTERS_ACCOUNT,
  EARMARK_MEMO_CONVENTION_ACCOUNT,
  EARMARK_MEMO_RECOUNT_ACCOUNT,
  PARTNERSHIP_RECOUNT_ACCOUNT_RECEIPT,
  PARTNERSHIP_ATTRIBUTION_RECOUNT_ACCOUNT_RECEIPT_MEMO,
  REFUND_TO_OTHER_POLITICAL_COMMITTEE,
  PARTNERSHIP_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO,
  PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_RECOUNT_JF_TRANSFER_MEMO,
  PARTNERSHIP_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO,
  PARTNERSHIP_ATTRIBUTION_NATIONAL_PARTY_HEADQUARTERS_JF_TRANSFER_MEMO,
  PARTY_IN_KIND_RECEIPT,
  IN_KIND_TRANSFER,
  IN_KIND_TRANSFER_FEDERAL_ELECTION_ACTIVITY,
  PAC_IN_KIND_RECEIPT,
  CONDUIT_EARMARK_RECEIPT,
  CONDUIT_EARMARK_RECEIPT_DEPOSITED: CONDUIT_EARMARK_RECEIPT,
  CONDUIT_EARMARK_RECEIPT_UNDEPOSITED: CONDUIT_EARMARK_RECEIPT,
  LOAN_RECEIVED_FROM_INDIVIDUAL_RECEIPT,
  LOAN_RECEIVED_FROM_BANK_RECEIPT,
  LOAN_REPAYMENT_RECEIVED,
  // Schedule B /////////////////////////////////////////////////////
  PAC_CONDUIT_EARMARK_OUT,
  PAC_CONDUIT_EARMARK_OUT_DEPOSITED: PAC_CONDUIT_EARMARK_OUT,
  PAC_CONDUIT_EARMARK_OUT_UNDEPOSITED: PAC_CONDUIT_EARMARK_OUT,
  BUSINESS_LABOR_REFUND_NON_CONTRIBUTION_ACCOUNT,
  INDIVIDUAL_REFUND_NON_CONTRIBUTION_ACCOUNT,
  OPERATING_EXPENDITURE,
  OPERATING_EXPENDITURE_VOID,
  INDIVIDUAL_REFUND_NP_HEADQUARTERS_ACCOUNT,
  INDIVIDUAL_REFUND_NP_CONVENTION_ACCOUNT,
  INDIVIDUAL_REFUND_NP_RECOUNT_ACCOUNT,
  IN_KIND_CONTRIBUTION_TO_CANDIDATE,
  IN_KIND_CONTRIBUTION_TO_OTHER_COMMITTEE,
  COORDINATED_PARTY_EXPENDITURE,
  COORDINATED_PARTY_EXPENDITURE_VOID,
  OTHER_DISBURSEMENT,
  OTHER_DISBURSEMENT_VOID,
  OTHER_DISBURSEMENT_STAFF_REIMBURSEMENT,
  OTHER_DISBURSEMENT_STAFF_REIMBURSEMENT_MEMO,
  OPERATING_EXPENDITURE_STAFF_REIMBURSEMENT,
  OPERATING_EXPENDITURE_STAFF_REIMBURSEMENT_MEMO,
  OPERATING_EXPENDITURE_CREDIT_CARD_PAYMENT,
  OPERATING_EXPENDITURE_CREDIT_CARD_PAYMENT_MEMO,
  OTHER_DISBURSEMENT_CREDIT_CARD_PAYMENT,
  OTHER_DISBURSEMENT_CREDIT_CARD_PAYMENT_MEMO,
  OPERATING_EXPENDITURE_PAYMENT_TO_PAYROLL,
  OPERATING_EXPENDITURE_PAYMENT_TO_PAYROLL_MEMO,
  OTHER_DISBURSEMENT_PAYMENT_TO_PAYROLL,
  OTHER_DISBURSEMENT_PAYMENT_TO_PAYROLL_MEMO,
  NON_CONTRIBUTION_ACCOUNT_DISBURSEMENT,
  NON_CONTRIBUTION_ACCOUNT_STAFF_REIMBURSEMENT,
  NON_CONTRIBUTION_ACCOUNT_STAFF_REIMBURSEMENT_MEMO,
  NON_CONTRIBUTION_ACCOUNT_CREDIT_CARD_PAYMENT,
  NON_CONTRIBUTION_ACCOUNT_CREDIT_CARD_PAYMENT_MEMO,
  NON_CONTRIBUTION_ACCOUNT_PAYMENT_TO_PAYROLL,
  NON_CONTRIBUTION_ACCOUNT_PAYMENT_TO_PAYROLL_MEMO,
  TRIBAL_REFUND_NP_CONVENTION_ACCOUNT,
  TRIBAL_REFUND_NP_HEADQUARTERS_ACCOUNT,
  TRIBAL_REFUND_NP_RECOUNT_ACCOUNT,
  REFUND_PARTY_CONTRIBUTION,
  REFUND_PARTY_CONTRIBUTION_VOID,
  REFUND_PAC_CONTRIBUTION,
  REFUND_PAC_CONTRIBUTION_VOID,
  REFUND_UNREGISTERED_CONTRIBUTION,
  REFUND_UNREGISTERED_CONTRIBUTION_VOID,
  REFUND_INDIVIDUAL_CONTRIBUTION,
  REFUND_INDIVIDUAL_CONTRIBUTION_VOID,
  OTHER_COMMITTEE_REFUND_NON_CONTRIBUTION_ACCOUNT,
  FEDERAL_ELECTION_ACTIVITY_100PCT_PAYMENT,
  FEDERAL_ELECTION_ACTIVITY_CREDIT_CARD_PAYMENT,
  FEDERAL_ELECTION_ACTIVITY_CREDIT_CARD_PAYMENT_MEMO,
  FEDERAL_ELECTION_ACTIVITY_STAFF_REIMBURSEMENT,
  FEDERAL_ELECTION_ACTIVITY_STAFF_REIMBURSEMENT_MEMO,
  FEDERAL_ELECTION_ACTIVITY_PAYMENT_TO_PAYROLL,
  FEDERAL_ELECTION_ACTIVITY_PAYMENT_TO_PAYROLL_MEMO,
  FEDERAL_ELECTION_ACTIVITY_VOID,
  OTHER_COMMITTEE_REFUND_REFUND_NP_HEADQUARTERS_ACCOUNT,
  OTHER_COMMITTEE_REFUND_REFUND_NP_CONVENTION_ACCOUNT,
  OTHER_COMMITTEE_REFUND_REFUND_NP_RECOUNT_ACCOUNT,
  TRANSFER_TO_AFFILIATES,
  CONTRIBUTION_TO_OTHER_COMMITTEE,
  CONTRIBUTION_TO_OTHER_COMMITTEE_VOID,
  RECOUNT_ACCOUNT_DISBURSEMENT,
  NATIONAL_PARTY_RECOUNT_ACCOUNT_DISBURSEMENT,
  NATIONAL_PARTY_HEADQUARTERS_ACCOUNT_DISBURSEMENT,
  NATIONAL_PARTY_CONVENTION_ACCOUNT_DISBURSEMENT,
  PARTY_IN_KIND_OUT,
  IN_KIND_TRANSFER_OUT,
  IN_KIND_TRANSFER_FEA_OUT,
  PAC_IN_KIND_OUT,
  CONDUIT_EARMARK_OUT,
  CONDUIT_EARMARK_OUT_DEPOSITED: CONDUIT_EARMARK_OUT,
  CONDUIT_EARMARK_OUT_UNDEPOSITED: CONDUIT_EARMARK_OUT,
  CONTRIBUTION_TO_CANDIDATE,
  CONTRIBUTION_TO_CANDIDATE_VOID,
  LOAN_MADE,
  LOAN_REPAYMENT_MADE,
  // Schedule C /////////////////////////////////////////////////////
  LOAN_RECEIVED_FROM_INDIVIDUAL,
  LOAN_RECEIVED_FROM_BANK,
  LOAN_BY_COMMITTEE,
  // Schedule C1 ////////////////////////////////////////////////////
  C1_LOAN_AGREEMENT,
  // Schedule C2 ////////////////////////////////////////////////////
  C2_LOAN_GUARANTOR,
  // Schedule D ////////////////////////////////////////////////////
  DEBT_OWED_BY_COMMITTEE,
  DEBT_OWED_TO_COMMITTEE,
  // Schedule E ////////////////////////////////////////////////////
  INDEPENDENT_EXPENDITURE,
  INDEPENDENT_EXPENDITURE_VOID,
  INDEPENDENT_EXPENDITURE_CREDIT_CARD_PAYMENT,
  INDEPENDENT_EXPENDITURE_PAYMENT_TO_PAYROLL,
  INDEPENDENT_EXPENDITURE_STAFF_REIMBURSEMENT,
  INDEPENDENT_EXPENDITURE_CREDIT_CARD_PAYMENT_MEMO,
  INDEPENDENT_EXPENDITURE_PAYMENT_TO_PAYROLL_MEMO,
  INDEPENDENT_EXPENDITURE_STAFF_REIMBURSEMENT_MEMO,
  MULTISTATE_INDEPENDENT_EXPENDITURE,
}

export class TransactionTypeUtils {
  static factory(transactionTypeIdentifier: string): TransactionType {
    const transactionType = getTransactionTypeClass(transactionTypeIdentifier);
    if (!transactionType) {
      throw new Error(`Fecfile: Class transaction type of '${transactionTypeIdentifier}' is not found`);
    }
    return new transactionType();
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTransactionTypeClass(transactionTypeIdentifier: string): any {
  return transactionTypeClasses[transactionTypeIdentifier];
}

// prettier-ignore
/**
 * Returns a schedule object of the correct class as discovered by examining
 * the scheduleId of the transaction type.
 *
 * This function is in this file because there is a REFERENCEERROR when it
 * is included in the transaction.model.ts file
 * @param json
 * @param depth
 * @returns
 */
export function getFromJSON(json: any, depth = 2): ScheduleTransaction { // eslint-disable-line @typescript-eslint/no-explicit-any
  if (json.line_label) json.line_label = json.line_label.replace(/^0+/, '');

  const transactionType = json.transaction_type_identifier ? TransactionTypeUtils.factory(json.transaction_type_identifier) : undefined;
  return getfromJsonByType(json, transactionType, depth);
}

export function PTY_ONLY(): TransactionTypes[] {
  return [
    ScheduleATransactionTypes.IN_KIND_TRANSFER_FEDERAL_ELECTION_ACTIVITY,
    ScheduleATransactionTypes.JF_TRANSFER_NATIONAL_PARTY_RECOUNT_ACCOUNT,
    ScheduleATransactionTypes.JF_TRANSFER_NATIONAL_PARTY_CONVENTION_ACCOUNT,
    ScheduleATransactionTypes.JF_TRANSFER_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
    ScheduleATransactionTypes.INDIVIDUAL_RECOUNT_RECEIPT,
    ScheduleATransactionTypes.PARTY_RECOUNT_RECEIPT,
    ScheduleATransactionTypes.PAC_RECOUNT_RECEIPT,
    ScheduleATransactionTypes.TRIBAL_RECOUNT_RECEIPT,
    ScheduleATransactionTypes.PARTNERSHIP_RECOUNT_ACCOUNT_RECEIPT,
    ScheduleATransactionTypes.INDIVIDUAL_NATIONAL_PARTY_RECOUNT_ACCOUNT,
    ScheduleATransactionTypes.PARTY_NATIONAL_PARTY_RECOUNT_ACCOUNT,
    ScheduleATransactionTypes.PAC_NATIONAL_PARTY_RECOUNT_ACCOUNT,
    ScheduleATransactionTypes.TRIBAL_NATIONAL_PARTY_RECOUNT_ACCOUNT,
    ScheduleATransactionTypes.PARTNERSHIP_NATIONAL_PARTY_RECOUNT_ACCOUNT,
    ScheduleATransactionTypes.INDIVIDUAL_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
    ScheduleATransactionTypes.PARTY_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
    ScheduleATransactionTypes.PAC_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
    ScheduleATransactionTypes.TRIBAL_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
    ScheduleATransactionTypes.PARTNERSHIP_NATIONAL_PARTY_HEADQUARTERS_ACCOUNT,
    ScheduleATransactionTypes.INDIVIDUAL_NATIONAL_PARTY_CONVENTION_ACCOUNT,
    ScheduleATransactionTypes.PARTY_NATIONAL_PARTY_CONVENTION_ACCOUNT,
    ScheduleATransactionTypes.PAC_NATIONAL_PARTY_CONVENTION_ACCOUNT,
    ScheduleATransactionTypes.TRIBAL_NATIONAL_PARTY_CONVENTION_ACCOUNT,
    ScheduleATransactionTypes.PARTNERSHIP_NATIONAL_PARTY_CONVENTION_ACCOUNT,
    ScheduleATransactionTypes.EARMARK_RECEIPT_FOR_RECOUNT_ACCOUNT_CONTRIBUTION,
    ScheduleATransactionTypes.EARMARK_RECEIPT_FOR_CONVENTION_ACCOUNT_CONTRIBUTION,
    ScheduleATransactionTypes.EARMARK_RECEIPT_FOR_HEADQUARTERS_ACCOUNT_CONTRIBUTION,
    ScheduleBTransactionTypes.RECOUNT_ACCOUNT_DISBURSEMENT,
    ScheduleBTransactionTypes.NATIONAL_PARTY_RECOUNT_ACCOUNT_DISBURSEMENT,
    ScheduleBTransactionTypes.NATIONAL_PARTY_HEADQUARTERS_ACCOUNT_DISBURSEMENT,
    ScheduleBTransactionTypes.NATIONAL_PARTY_CONVENTION_ACCOUNT_DISBURSEMENT,
    ScheduleBTransactionTypes.INDIVIDUAL_REFUND_NP_HEADQUARTERS_ACCOUNT,
    ScheduleBTransactionTypes.OTHER_COMMITTEE_REFUND_REFUND_NP_HEADQUARTERS_ACCOUNT,
    ScheduleBTransactionTypes.TRIBAL_REFUND_NP_HEADQUARTERS_ACCOUNT,
    ScheduleBTransactionTypes.INDIVIDUAL_REFUND_NP_CONVENTION_ACCOUNT,
    ScheduleBTransactionTypes.OTHER_COMMITTEE_REFUND_REFUND_NP_CONVENTION_ACCOUNT,
    ScheduleBTransactionTypes.TRIBAL_REFUND_NP_CONVENTION_ACCOUNT,
    ScheduleBTransactionTypes.INDIVIDUAL_REFUND_NP_RECOUNT_ACCOUNT,
    ScheduleBTransactionTypes.OTHER_COMMITTEE_REFUND_REFUND_NP_RECOUNT_ACCOUNT,
    ScheduleBTransactionTypes.TRIBAL_REFUND_NP_RECOUNT_ACCOUNT,
    ScheduleBTransactionTypes.FEDERAL_ELECTION_ACTIVITY_100PCT_PAYMENT,
    ScheduleBTransactionTypes.FEDERAL_ELECTION_ACTIVITY_CREDIT_CARD_PAYMENT,
    ScheduleBTransactionTypes.FEDERAL_ELECTION_ACTIVITY_STAFF_REIMBURSEMENT,
    ScheduleBTransactionTypes.FEDERAL_ELECTION_ACTIVITY_PAYMENT_TO_PAYROLL,
    ScheduleBTransactionTypes.FEDERAL_ELECTION_ACTIVITY_VOID,
    ScheduleBTransactionTypes.PAC_IN_KIND_OUT,
    ScheduleFTransactionTypes.COORDINATED_PARTY_EXPENDITURE,
    ScheduleFTransactionTypes.COORDINATED_PARTY_EXPENDITURE_VOID,
  ];
}

export function PAC_ONLY(): TransactionTypes[] {
  return [
    ScheduleATransactionTypes.INDIVIDUAL_RECEIPT_NON_CONTRIBUTION_ACCOUNT,
    ScheduleATransactionTypes.OTHER_COMMITTEE_RECEIPT_NON_CONTRIBUTION_ACCOUNT,
    ScheduleATransactionTypes.BUSINESS_LABOR_NON_CONTRIBUTION_ACCOUNT,
    ScheduleBTransactionTypes.NON_CONTRIBUTION_ACCOUNT_DISBURSEMENT,
    ScheduleBTransactionTypes.NON_CONTRIBUTION_ACCOUNT_CREDIT_CARD_PAYMENT,
    ScheduleBTransactionTypes.NON_CONTRIBUTION_ACCOUNT_STAFF_REIMBURSEMENT,
    ScheduleBTransactionTypes.NON_CONTRIBUTION_ACCOUNT_PAYMENT_TO_PAYROLL,
    ScheduleBTransactionTypes.INDIVIDUAL_REFUND_NON_CONTRIBUTION_ACCOUNT,
    ScheduleBTransactionTypes.OTHER_COMMITTEE_REFUND_NON_CONTRIBUTION_ACCOUNT,
    ScheduleBTransactionTypes.BUSINESS_LABOR_REFUND_NON_CONTRIBUTION_ACCOUNT,
  ];
}

export function MultipleEntryTransactionTypes(): string[] {
  return [
    ScheduleATransactionTypes.CONDUIT_EARMARK_RECEIPT_UNDEPOSITED,
    ScheduleATransactionTypes.CONDUIT_EARMARK_RECEIPT_DEPOSITED,
    ScheduleATransactionTypes.EARMARK_RECEIPT_FOR_CONVENTION_ACCOUNT_CONTRIBUTION,
    ScheduleATransactionTypes.EARMARK_RECEIPT_FOR_HEADQUARTERS_ACCOUNT_CONTRIBUTION,
    ScheduleATransactionTypes.EARMARK_RECEIPT_FOR_RECOUNT_ACCOUNT_CONTRIBUTION,
    ScheduleATransactionTypes.EARMARK_RECEIPT,
    ScheduleATransactionTypes.IN_KIND_RECEIPT,
    ScheduleATransactionTypes.IN_KIND_TRANSFER_FEDERAL_ELECTION_ACTIVITY,
    ScheduleATransactionTypes.IN_KIND_TRANSFER,
    ScheduleCTransactionTypes.LOAN_BY_COMMITTEE,
    ScheduleCTransactionTypes.LOAN_RECEIVED_FROM_INDIVIDUAL,
    ScheduleATransactionTypes.PAC_CONDUIT_EARMARK_RECEIPT_UNDEPOSITED,
    ScheduleATransactionTypes.PAC_CONDUIT_EARMARK_RECEIPT_DEPOSITED,
    ScheduleATransactionTypes.PAC_EARMARK_RECEIPT,
    ScheduleATransactionTypes.PAC_IN_KIND_RECEIPT,
    ScheduleATransactionTypes.PARTY_IN_KIND_RECEIPT,
    ScheduleCTransactionTypes.LOAN_RECEIVED_FROM_BANK,
  ];
}

function getfromJsonByType(
  json: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  transactionType: TransactionType | undefined,
  depth: number,
): ScheduleTransaction {
  if (transactionType) {
    if (transactionType.scheduleId === ScheduleIds.A) return SchATransaction.fromJSON(json, depth);
    if (transactionType.scheduleId === ScheduleIds.B) return SchBTransaction.fromJSON(json, depth);
    if (transactionType.scheduleId === ScheduleIds.C) return SchCTransaction.fromJSON(json, depth);
    if (transactionType.scheduleId === ScheduleIds.C1) return SchC1Transaction.fromJSON(json, depth);
    if (transactionType.scheduleId === ScheduleIds.C2) return SchC2Transaction.fromJSON(json, depth);
    if (transactionType.scheduleId === ScheduleIds.D) return SchDTransaction.fromJSON(json, depth);
    if (transactionType.scheduleId === ScheduleIds.E) return SchETransaction.fromJSON(json, depth);
    if (transactionType.scheduleId === ScheduleIds.F) return SchFTransaction.fromJSON(json, depth);
  }
  return SchATransaction.fromJSON(json, depth); // Until 404 resolved
}

import { TransactionType, TransactionTemplateMapType } from './transaction-type.model';
import { ScheduleIds } from './transaction.model';

export abstract class SchC2TransactionType extends TransactionType {
  scheduleId = ScheduleIds.C2;
  override amountInputHeader = 'Guaranteed financial information';

  // Mapping of schedule fields to the group input component form templates
  templateMap: TransactionTemplateMapType = {
    // Form fields
    last_name: 'guarantor_last_name',
    first_name: 'guarantor_first_name',
    middle_name: 'guarantor_middle_name',
    prefix: 'guarantor_prefix',
    suffix: 'guarantor_suffix',
    street_1: 'guarantor_street_1',
    street_2: 'guarantor_street_2',
    city: 'guarantor_city',
    state: 'guarantor_state',
    zip: 'guarantor_zip',
    employer: 'guarantor_employer',
    occupation: 'guarantor_occupation',
    organization_name: '',
    committee_fec_id: '',
    committee_name: '',
    candidate_fec_id: '',
    candidate_last_name: '',
    candidate_first_name: '',
    candidate_middle_name: '',
    candidate_prefix: '',
    candidate_suffix: '',
    candidate_office: '',
    candidate_state: '',
    candidate_district: '',
    payment_to_date: '',
    interest_rate: '',
    due_date: '',
    interest_rate_setting: '',
    due_date_setting: '',
    secured: '',
    date: '',
    date2: '',
    memo_code: '',
    amount: 'guaranteed_amount',
    balance: '',
    aggregate: '',
    calendar_ytd: '',
    purpose_description: '',
    text4000: 'text4000',
    category_code: '',
    election_code: '',
    election_other_description: '',
    secondary_name: '',
    secondary_street_1: '',
    secondary_street_2: '',
    secondary_city: '',
    secondary_state: '',
    secondary_zip: '',
    signatory_1_last_name: '',
    signatory_1_first_name: '',
    signatory_1_middle_name: '',
    signatory_1_prefix: '',
    signatory_1_suffix: '',
    signatory_1_date: '',
    signatory_2_last_name: '',
    signatory_2_first_name: '',
    signatory_2_middle_name: '',
    signatory_2_prefix: '',
    signatory_2_suffix: '',
    signatory_2_title: '',
    signatory_2_date: '',
    quaternary_committee_fec_id: '',
    quaternary_committee_name: '',
    quinary_committee_fec_id: '',
    quinary_committee_name: '',
    quinary_street_1: '',
    quinary_street_2: '',
    quinary_city: '',
    quinary_state: '',
    quinary_zip: '',
  };
}

@if (formSubmitted || (control?.invalid && (control?.dirty || (control?.touched && control?.updateOn !== 'change')))) {
  @if (control?.hasError('required')) {
    <small class="p-error">{{ requiredErrorMessage }}</small>
  }
  @if (control?.hasError('fecIdMustBeUnique')) {
    <small class="p-error">{{ uniqueFecIdMessage }}</small>
  }
  @if (control?.hasError('requiredTrue')) {
    <small class="p-error">{{ requiredTrueErrorMessage }}</small>
  }
  @if (control?.hasError('minlength')) {
    <small class="p-error">{{ minLengthErrorMessage }}</small>
  }
  @if (!control?.hasError('max') && control?.hasError('maxlength')) {
    <small class="p-error">{{ maxLengthErrorMessage }}</small>
  }
  @if (control?.hasError('min')) {
    <small class="p-error">{{ minErrorMessage }}</small>
  }
  @if (control?.hasError('max')) {
    <small class="p-error">{{ maxErrorMessage }}</small>
  }
  @if (control?.hasError('exclusiveMax')) {
    <small class="p-error">{{ exclusiveMaxErrorMessage }}</small>
  }
  @if (control?.hasError('exclusiveMin')) {
    <small class="p-error">{{ exclusiveMinErrorMessage }}</small>
  }
  @if (control?.hasError('invaliddate')) {
    <small class="p-error">{{ invalidDateErrorMessage }}</small>
  }
  @if (control?.hasError('noDateProvided')) {
    <small class="p-error">{{ noDateProvidedErrorMessage }}</small>
  }
  @if (control?.hasError('noCorrespondingForm3X')) {
    <small class="p-error">{{ noCorrespondingForm3XErrorMessage }}</small>
  }
  @if (!control?.hasError('maxlength') && !control?.hasError('required') && control?.hasError('email')) {
    <small class="p-error">{{ emailErrorMessage }}</small>
  }
  @if (control?.hasError('isAfter')) {
    <small class="p-error">{{ isAfterMessage }}</small>
  }
  @if (
    !control?.hasError('required') &&
    !control?.hasError('minlength') &&
    !control?.hasError('maxlength') &&
    !control?.hasError('min') &&
    !control?.hasError('max') &&
    !control?.hasError('exclusiveMax') &&
    !control?.hasError('exclusiveMin') &&
    !control?.hasError('invaliddate') &&
    !control?.hasError('isAfter') &&
    control?.hasError('pattern')
  ) {
    <small class="p-error">{{ patternErrorMessage }}</small>
  }
}

<p-dialog [modal]="true" [(visible)]="dialogVisible" (onHide)="closeDialog()" [style]="{ width: '512px' }">
  <ng-template #header>
    <h1>Create a new report</h1>
  </ng-template>
  <div class="dialog-body">
    <div class="dropdown">
      <span id="type-label">FORM TYPE</span>
      <p-select [options]="formTypeOptions" [(ngModel)]="selectedType" class="w-full" appendTo="body" id="typeDropdown">
        <ng-template #dropdownicon>
          <img alt="Dropdown arrow" src="assets/img/dropdown_arrow.svg" height="8px" width="8px" />
        </ng-template>
        <ng-template #selectedItem let-selectedOption>
          <span class="option">
            <b>{{ getFormType(selectedType)?.label }}:</b> {{ getFormType(selectedType)?.description }}
          </span>
        </ng-template>
        <ng-template let-type #item>
          <span
            class="option"
            (click)="updateSelected(type)"
            (keydown.enter)="updateSelected(type)"
            [attr.data-cy]="type"
          >
            <b>{{ getFormType(type)?.label }}:</b> {{ getFormType(type)?.description }}
          </span>
        </ng-template>
      </p-select>
    </div>
    @if (selectedType === formTypes.F24) {
      <div class="modal-item-margin">
        <label for="form24Options">REPORT TYPE</label>

        <p-selectbutton
          inputid="form24Options"
          [options]="form24Options"
          [(ngModel)]="selectedForm24Type"
          optionLabel="label"
          optionValue="value"
        />
      </div>
    }
  </div>
  <ng-template #footer>
    <div class="flex justify-content-center w-full pb-3">
      <button
        id="submit-button"
        pButton
        pRipple
        [disabled]="isSubmitDisabled"
        label="Start building report"
        data-cy="start-report"
        class="p-button-primary"
        (click)="goToReportForm()"
      ></button>
    </div>
  </ng-template>
</p-dialog>

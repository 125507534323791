<div class="report-menu">
  <div class="grid report-info">
    <div class="col-12">
      <div class="title-2">REPORT PROGRESS</div>
      <div class="report-type">{{ formLabel }}</div>
      <div class="sub-heading">{{ subLabel }}</div>
      <div class="sub-heading">
        {{ coverage_from_date | fecDate }} — <wbr />
        {{ coverage_through_date | fecDate }}
      </div>
    </div>
  </div>
  @if (items$ | async) {
    <div class="pl-1 pt-2">
      <p-panelMenu [model]="(items$ | async)!" [multiple]="false"></p-panelMenu>
    </div>
  }
</div>
